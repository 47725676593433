import { render, staticRenderFns } from "./Nav.vue?vue&type=template&id=0641c240&"
import script from "./Nav.vue?vue&type=script&lang=js&"
export * from "./Nav.vue?vue&type=script&lang=js&"
import style0 from "@cognitoforms/styles/global/_motion.scss?vue&type=style&index=0&prod&lang=scss&"
import style1 from "@cognitoforms/styles/website/hamburger.scss?vue&type=style&index=1&prod&lang=scss&"
import style2 from "./Nav.vue?vue&type=style&index=2&id=0641c240&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.10.0_babel-core@7.0.0-bridge.0_css-loader@3.6.0_react-dom@16.14.0_react@16.14.0_63j34gpdvqalp2jusxxt2t2wn4/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports